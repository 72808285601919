import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Grid, Paper, Typography } from "@material-ui/core"

const useStyles = makeStyles({
  paper1: {
    backgroundColor: "white",
  },
  paper2: {
    backgroundColor: "white",
  },
})

const About = ({ data, location }) => {
  const classes = useStyles()
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <>
      <Layout location={location} title={siteTitle}>
        <Seo title="A propos" />
        <h1>About me</h1>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper1}>
              <Typography variant="subtitle1">
                Je suis un ancien développeur Wordpress converti depuis à React
                et aux technologies Mernstack, Jamstack.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper2}>
              <Typography variant="body1">
                Ici, vous trouverez des articles sur le développement web, des
                tutos ou des ressources pour les dév.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}

export default About

About.propTypes = {
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  }),
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
